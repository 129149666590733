import { PlanStatus } from 'src/utils/constants/enum';
import {
  ICustomerId,
  ICheckoutSession,
  IBillingDetail,
  IPaymentHistory,
  IUsageInformation,
} from 'src/utils/typings/response/payment';
import { IPlanDetail } from 'src/utils/typings/response/plan';

export class CustomerId implements IResponse<ICustomerId> {
  message?: string | undefined;
  status_code: number;
  data: ICustomerId;
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.data = {
      id: '',
    };
  }
}

export class CheckoutSession implements IResponse<ICheckoutSession> {
  message?: string | undefined;
  status_code: number;
  data: ICheckoutSession;
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.data = {
      sessionId: '',
    };
  }
}

export class BillingDetail implements IResponse<IBillingDetail> {
  message?: string | undefined;
  status_code: number;
  data: IBillingDetail;
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.data = {
      id: 0,
      email: '',
      last_name: '',
      phone_number: '',
      country: '',
      city: '',
      postal_code: '',
      user_uuid: '',
      first_name: '',
      company_name: '',
      region: '',
      address: '',
      vat_id: '',
    };
  }
}

export class ListPlans implements IResponse<IPlanDetail[]> {
  message?: string | undefined;
  status_code: number;
  data: IPlanDetail[];
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.data = [];
  }
}

export class PlanDetail implements IResponse<IPlanDetail> {
  message?: string | undefined;
  status_code: number;
  data: IPlanDetail;
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.data = {
      id: 0,
      plan_name: '',
      short_description: '',
      benefits: [],
      status: PlanStatus.Active,
      display_for_sale: true,
      price_monthly: null,
      price_quarterly: null,
      price_half_yearly: null,
      price_yearly: null,
      credit_points_per_month: null,
      stripe_price_id_quarterly: '',
      stripe_price_id_half_yearly: '',
      stripe_price_id_yearly: '',
      stripe_product_id: '',
      stripe_price_id_monthly: '',
      created_at: null,
      updated_at: null,
      expired_at: null,
      is_trial: false,
    };
  }
}

export class ListInvoices implements IResponseList<IPaymentHistory> {
  message?: string;
  status_code: number;
  warning?: string | null | undefined;
  data: IPaymentHistory[];
  errors?: ExpectedAny[] | undefined;
  page: number;
  page_size: number;
  total_pages: number;
  total_records: number;
  constructor() {
    (this.status_code = 200), (this.data = []);
    this.page = 0;
    this.page_size = 0;
    this.total_pages = 0;
    this.total_records = 0;
  }
}

export class InvoiceDetail implements IResponse<ExpectedAny> {
  message?: string | undefined;
  status_code: number;
  data: ExpectedAny;
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.data = {};
  }
}

export class InvoiceStripeDetail implements IResponse<ExpectedAny> {
  message?: string | undefined;
  status_code: number;
  data: ExpectedAny;
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.data = {};
  }
}

export class UsageInformation implements IResponse<IUsageInformation> {
  message?: string | undefined;
  status_code: number;
  data: IUsageInformation;
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.data = {
      usage_total: 0,
      members: 0,
      usage_date: '',
      current_usage: 0,
      user_uuid: '',
      id: 0,
      total_members: 0,
      current_product: '',
      renewal_date: '',
      created_at: '',
      invoice_amount: 0,
      period_end: '',
      subscription_id: '',
      features: [],
      is_trial: false,
    };
  }
}
