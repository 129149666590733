import { CommonResponse } from 'src/model/common';
import { URL_ENDPOINT } from 'src/utils/constants/service';
import { postRequest, getRequest } from './http';
import { IBillingDetailPayload, ICheckoutSessionPayload, IGetListInvoicesPayload } from 'src/utils/typings/dto/payment';
import {
  CustomerId,
  CheckoutSession,
  BillingDetail,
  ListPlans,
  PlanDetail,
  ListInvoices,
  UsageInformation,
} from 'src/model/payment';

function updateCustomerInformation(dto: IBillingDetailPayload) {
  const url = URL_ENDPOINT.payment.checkout + 'update-customer';
  return postRequest(url, dto) as Promise<CommonResponse>;
}

function cancelSubscription(subscriptionId: string) {
  const url = URL_ENDPOINT.payment.checkout + 'cancel-subscription/' + subscriptionId;
  return getRequest(url, {}) as Promise<CommonResponse>;
}

function getCustomerId() {
  const url = URL_ENDPOINT.payment.checkout + 'get-customer-id';
  return getRequest(url, {}, new CustomerId()) as Promise<CustomerId>;
}

function getCheckoutSession(dto: ICheckoutSessionPayload) {
  const url = URL_ENDPOINT.payment.checkout + 'create-checkout-session';
  return postRequest(url, dto) as Promise<CheckoutSession>;
}

function getBillingDetail() {
  const url = URL_ENDPOINT.payment.checkout + 'get-billing-details';
  return getRequest(url, {}, new BillingDetail()) as Promise<BillingDetail>;
}

function getPlans() {
  const url = URL_ENDPOINT.payment.plan + 'prices';
  return getRequest(url, {}, new ListPlans()) as Promise<ListPlans>;
}

function getPlanDetail(id: number) {
  const url = URL_ENDPOINT.payment.plan + 'detail/' + id;
  return getRequest(url, {}, new PlanDetail()) as Promise<PlanDetail>;
}

function getInvoices(query: IGetListInvoicesPayload) {
  const url = URL_ENDPOINT.payment.checkout + 'get-history-invoice';
  return getRequest(url, query, new ListInvoices()) as Promise<ListInvoices>;
}

function getInvoiceDetail(id: string) {
  const url = URL_ENDPOINT.payment.checkout + 'get-invoice/' + id;
  return getRequest(url, {}, new CommonResponse()) as Promise<CommonResponse>;
}

function getInvoiceStripeBySessionId(sessionId: string) {
  const url = URL_ENDPOINT.payment.checkout + 'get-invoice-details-by-session-id/' + sessionId;
  return getRequest(url, {}, new CommonResponse()) as Promise<CommonResponse>;
}

function getUsage() {
  const url = URL_ENDPOINT.payment.checkout + 'get-usage';
  return getRequest(url, {}, new UsageInformation()) as Promise<UsageInformation>;
}

function checkEntityPaid({ entityId, type }: { entityId: string; type: string }) {
  const url = URL_ENDPOINT.payment.checkout + 'check-entity-id-type-created-30-days';
  return getRequest(url, { entity_id: entityId, type }, new CommonResponse()) as Promise<CommonResponse>;
}

function calculatePricePlan({ priceId }: { priceId: string }) {
  const url = URL_ENDPOINT.payment.checkout + 'calculate-price-before-change-plan';
  return postRequest(url, { price_id: priceId }) as Promise<CommonResponse>;
}

function changePlan({ priceId }: { priceId: string }) {
  const url = URL_ENDPOINT.payment.checkout + 'update-plan';
  return postRequest(url, { price_id: priceId }) as Promise<CommonResponse>;
}

function subscriptionFreeTrial({ planId }: { planId: number }) {
  const url = URL_ENDPOINT.payment.plan + 'subcription-trial';
  return postRequest(url, { plan_id: planId }) as Promise<CommonResponse>;
}

const PaymentService = {
  updateCustomerInformation,
  cancelSubscription,
  getCustomerId,
  getCheckoutSession,
  getBillingDetail,
  getPlans,
  getPlanDetail,
  getInvoices,
  getInvoiceDetail,
  getInvoiceStripeBySessionId,
  getUsage,
  checkEntityPaid,
  calculatePricePlan,
  changePlan,
  subscriptionFreeTrial,
};

export default PaymentService;
