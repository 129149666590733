import { useQuery } from '@tanstack/react-query';
import { PaymentService } from 'src/services/api';
import {
  CustomerId,
  BillingDetail,
  ListPlans,
  PlanDetail,
  InvoiceDetail,
  ListInvoices,
  InvoiceStripeDetail,
  UsageInformation,
} from 'src/model/payment';
import { IInvoiceDetail } from 'src/utils/typings/response/payment';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { FORMAT_DATE_TIME } from 'src/utils/constants/config';
import { IGetListInvoicesPayload } from 'src/utils/typings/dto/payment';

const QUERY_KEY_CUSTOMER_ID = 'QUERY_KEY_CUSTOMER_ID';
const QUERY_KEY_BILLING_DETAIL = 'QUERY_KEY_BILLING_DETAIL';
const QUERY_KEY_LIST_PLANS = 'QUERY_KEY_LIST_PLANS';
const QUERY_KEY_LIST_INVOICES = 'QUERY_KEY_LIST_INVOICES';
const QUERY_KEY_INVOICE_STRIPE = 'QUERY_KEY_INVOICE_STRIPE';
export const QUERY_KEY_USAGE = 'QUERY_KEY_USAGE';

export const useQueryCustomerId = () => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_CUSTOMER_ID],
    queryFn: () => PaymentService.getCustomerId(),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new CustomerId(),
    placeholderData: (previousData) => previousData,
  });

  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryBillingDetail = () => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_BILLING_DETAIL],
    queryFn: () => PaymentService.getBillingDetail(),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new BillingDetail(),
    placeholderData: (previousData) => previousData,
  });

  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryListPlans = () => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_LIST_PLANS],
    queryFn: () => PaymentService.getPlans(),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new ListPlans(),
    placeholderData: (previousData) => previousData,
  });

  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryPlanDetail = (id: number) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_LIST_PLANS, id],
    queryFn: () => PaymentService.getPlanDetail(id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new PlanDetail(),
    placeholderData: (previousData) => previousData,
  });

  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryListInvoices = (query: IGetListInvoicesPayload) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_LIST_INVOICES],
    queryFn: () => PaymentService.getInvoices(query),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new ListInvoices(),
    placeholderData: (previousData) => previousData,
  });

  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryInvoiceDetail = (id: string) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_LIST_INVOICES, id],
    queryFn: () => PaymentService.getInvoiceDetail(id),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new InvoiceDetail(),
    placeholderData: (previousData) => previousData,
    enabled: !!id,
  });

  const dataMapping: IInvoiceDetail = {
    id: get(data?.data, 'invoice.id', ''),
    payment_date: get(data?.data, 'history_invoice_data.payment_date', ''),
    status: get(data?.data, 'history_invoice_data.status', ''),
    payment_method: get(data?.data, 'history_invoice_data.payment_method', ''),
    customer_name: get(data?.data, 'invoice.customer_name', ''),
    customer_email: get(data?.data, 'invoice.customer_email', ''),
    customer_phone: get(data?.data, 'invoice.customer_phone', ''),
    customer_address: get(data?.data, 'invoice.customer_address', ''),
    plan: {
      name: get(data?.data, 'product.name', ''),
      recurring_interval: get(data?.data, 'invoice.lines.data[0].price.recurring.interval', ''),
      recurring_interval_count: get(data?.data, 'invoice.lines.data[0].price.recurring.interval_count', ''),
      amount: get(data?.data, 'history_invoice_data.amount', 0),
      // price: get(data?.data, 'invoice.total', 0),
      // quantity: get(data?.data, 'invoice.lines.total_count', 0),
    },
    period_end: dayjs(get(data?.data, 'invoice.lines.data[0].period.end', 0) * 1000).format(FORMAT_DATE_TIME.UTC),
  };

  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data: dataMapping,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryInvoiceStripeBySessionId = (sessionId: string) => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_INVOICE_STRIPE, sessionId],
    queryFn: () => PaymentService.getInvoiceStripeBySessionId(sessionId),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new InvoiceStripeDetail(),
    placeholderData: (previousData) => previousData,
  });

  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};

export const useQueryUsageInformation = () => {
  const { isLoading, error, data, isError, isFetching, isLoadingError, isPending, refetch } = useQuery({
    queryKey: [QUERY_KEY_USAGE],
    queryFn: () => PaymentService.getUsage(),
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: new UsageInformation(),
    placeholderData: (previousData) => previousData,
  });

  return {
    isLoadingError,
    isFetching,
    isLoading,
    error,
    data,
    isError,
    isPending,
    refetch,
  };
};
