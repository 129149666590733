import { Box, Typography, useTheme } from '@mui/material';
import Lottie from 'react-lottie';

import TechnySingle from 'src/asset/lottie/techny-single.json';

// Components

function SmallScreenNotice() {
  const theme = useTheme();

  return (
    <Box position="relative" height="100vh" width="100vw" bgcolor={theme.palette.background.paper1}>
      <Box
        width="80vw"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: TechnySingle,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height="70%"
          width="70%"
        />
        <Typography variant="h4" align="center" sx={{ color: '#3658C1' }}>
          Not Supported on Mobile & Tablets
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center" mt={2}>
          Our website is not optimised for small screens, including mobile phones and tablets. For the best experience,
          please use a <b>laptop</b> or <b>desktop</b>. We appreciate your understanding.
        </Typography>
      </Box>
    </Box>
  );
}

export default SmallScreenNotice;
