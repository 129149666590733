export const SUCCESS_CODE = {
  GET: 200,
  CREATE: 201,
};

export const ERROR_CODE = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  EXPECTATION_FAILED: 417,
  VALIDATION: 422,
  NOT_ACCEPTABLE: 406,
  BAD_REQUEST: 400,
  USER_EXISTED: 302,
  REACH_LIMIT_CREDITS: 405,
  NO_HAVE_ANY_CREDIT: 409,
  NO_HAVE_ENOUGH_CREDIT: 410,
};
