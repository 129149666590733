export class CommonResponse implements IResponse<ExpectedAny> {
  message?: string;
  status_code: number;
  warning?: string | null;
  data?: ExpectedAny;
  detail?: string;
  errors?: ExpectedAny[];
  public_link?: string;
  public_links?: { message: string; file_name: string; status_code: number }[];
  request_id?: string;
  check_entity_id_type_created_30_days_result?: boolean;
  constructor() {
    this.message = '';
    this.status_code = 0;
    this.warning = null;
    this.public_link = '';
    this.public_links = [];
    this.request_id = '';
    this.check_entity_id_type_created_30_days_result = false;
  }
}
export class CommonResponseList implements IResponseList<ExpectedAny> {
  message?: string;
  status_code: number;
  warning?: string | null;
  data: ExpectedAny[];
  detail?: string;
  errors?: ExpectedAny[];
  public_link?: string;
  public_links?: { message: string; file_name: string; status_code: number }[];
  request_id?: string;
  constructor() {
    this.data = [];
    this.message = '';
    this.status_code = 0;
    this.warning = null;
    this.public_link = '';
    this.public_links = [];
    this.request_id = '';
    this.page = 0;
    this.page_size = 0;
    this.total_pages = 0;
    this.total_records = 0;
  }
  page: number;
  page_size: number;
  total_pages: number;
  total_records: number;
  total_docs_count?: number | undefined;
}
