import { Box, Stack, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import numeral from 'numeral';
import Lottie from 'react-lottie';

// Components
import useCountDown from 'src/hooks/useCountDown';

// Utilities
import maintainLottie from 'src/asset/lottie/maintain.json';

function MaintainPage() {
  const theme = useTheme();

  const startDate = dayjs();
  const endDate = dayjs('2024-11-19');
  const secondsDiff = endDate.diff(startDate, 'second');

  const { timeLeft } = useCountDown(secondsDiff, true);

  const seconds = numeral(Math.floor(timeLeft % 60)).format('00');
  const minutes = numeral(Math.floor((timeLeft % 3600) / 60)).format('00');
  const hours = numeral(Math.floor(timeLeft / 3600 / 24)).format('00');
  const days = numeral(Math.floor(timeLeft / (3600 * 24))).format('00');

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      sx={{
        minHeight: '100dvh',
        overflow: 'hidden',
      }}
      bgcolor={theme.palette.background.paper1}
    >
      <Stack spacing={3} alignItems={'center'}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: maintainLottie,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={467}
          width={622}
        />
        <Box textAlign={'center'}>
          <Typography color={'#3658C1'} variant="h4">
            Sorry! We are currently down for maintenance.
          </Typography>
          <Typography color={'#3658C1'} variant="h4">
            But don't worry, we will be back up soon.
          </Typography>
        </Box>
        <Stack spacing={6} direction={'row'} alignItems={'center'}>
          <Stack alignItems={'center'}>
            <Typography fontWeight={900} variant="h2" color={'secondary.main'}>
              {days}
            </Typography>
            <Typography variant="h6" color={'#94A3B8'}>
              Days
            </Typography>
          </Stack>
          <Stack alignItems={'center'}>
            <Typography fontWeight={900} variant="h2" color={'secondary.main'}>
              {hours}
            </Typography>
            <Typography variant="h6" color={'#94A3B8'}>
              Hours
            </Typography>
          </Stack>
          <Stack alignItems={'center'}>
            <Typography fontWeight={900} variant="h2" color={'secondary.main'}>
              {minutes}
            </Typography>
            <Typography variant="h6" color={'#94A3B8'}>
              Minutes
            </Typography>
          </Stack>
          <Stack alignItems={'center'}>
            <Typography fontWeight={900} variant="h2" color={'secondary.main'}>
              {seconds}
            </Typography>
            <Typography variant="h6" color={'#94A3B8'}>
              Seconds
            </Typography>
          </Stack>
        </Stack>
        <Typography lineHeight={'116px'} fontWeight={900} fontSize={145} color={'rgba(148, 163, 184, 0.10)'}>
          COMING SOON
        </Typography>
      </Stack>
    </Box>
  );
}

export default MaintainPage;
