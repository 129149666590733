//SERVICE
export const USER_SERVICE = '/user-service/';
export const DATA_SERVICE = '/data-service/';
export const PAYMENT_SERVICE = 'payment-service/';
export const TRADE_DATA_SERVICE = '/trade-service/';

//ENDPOINT URL
export const URL_ENDPOINT = {
  user: {
    users: USER_SERVICE + 'users/',
    utils: USER_SERVICE + 'utils/',
  },
  company: {
    commons: DATA_SERVICE + 'commons/',
    companies: DATA_SERVICE + 'companies/',
    tariffs: DATA_SERVICE + 'tariffs/',
    utils: DATA_SERVICE + 'utils/',
  },
  people: {
    people: DATA_SERVICE + 'people/',
  },
  payment: {
    checkout: PAYMENT_SERVICE + 'payment/',
    plan: PAYMENT_SERVICE + 'plan/',
  },
  tradeData: {
    hsCode: TRADE_DATA_SERVICE + 'hscodes',
    hs6: TRADE_DATA_SERVICE + 'hscodes/hs6',
    ports: TRADE_DATA_SERVICE + 'ports',
    entity: TRADE_DATA_SERVICE + 'companies',
    entityDetail: TRADE_DATA_SERVICE + 'companies/:id',
    transactions: TRADE_DATA_SERVICE + 'transactions',
    hsCodeDetail: TRADE_DATA_SERVICE + 'hscodes/:id',
    marketAnalyticsChart: TRADE_DATA_SERVICE + 'transactions/analysis/market/chart',
    marketAnalyticsTable: TRADE_DATA_SERVICE + 'transactions/analysis/market/table',
    marketAnalyticsCompany: TRADE_DATA_SERVICE + 'transactions/analysis/market/company',
    marketAnalyticsWorldMap: TRADE_DATA_SERVICE + 'transactions/analysis/market/worldmap',
    supplierBuyerCountryWorldMap: TRADE_DATA_SERVICE + 'transactions/analysis/domain-country/worldmap',
    supplierBuyerCountryTable: TRADE_DATA_SERVICE + 'transactions/analysis/domain-country/table',
    supplierBuyerCountryCompany: TRADE_DATA_SERVICE + 'transactions/analysis/domain-country/company',
    transactionsCount: TRADE_DATA_SERVICE + 'transactions/count',
    transactionsPaymentStatus: TRADE_DATA_SERVICE + 'transactions/payment-status',
  },
  companyTradeData: {
    transactions: TRADE_DATA_SERVICE + 'companies/:id/transactions',
    buyer: {
      list: TRADE_DATA_SERVICE + 'companies/:id/buyers/list',
      chart: TRADE_DATA_SERVICE + 'companies/:id/buyers/chart',
      map: TRADE_DATA_SERVICE + 'companies/:id/buyers/worldmap',
    },
    supplier: {
      list: TRADE_DATA_SERVICE + 'companies/:id/suppliers/list',
      chart: TRADE_DATA_SERVICE + 'companies/:id/suppliers/chart',
      map: TRADE_DATA_SERVICE + 'companies/:id/suppliers/worldmap',
    },
    competitor: {
      chart: TRADE_DATA_SERVICE + 'companies/:id/market_share/chart',
      list: TRADE_DATA_SERVICE + 'companies/:id/market_share/list',
      map: TRADE_DATA_SERVICE + 'companies/:id/market_share/worldmap',
    },
  },
};
